<template>
  <div class="cart">
    <div class="container">
      <div class="row">
        <div class="col-md-6 cart-left">
          <div class="_header">
            <h5>Checkout</h5>
          </div>
          <div class="_body">
            <div class="item-card">
              <div class="thumbnail">
                <img src="../assets/module-illus.png" alt="" />
              </div>
              <div v-if="loading" class="loading">Loading...</div>
              <div v-else-if="checkoutData" class="content">
                <div class="linear">
                  <div>
                    <h5>{{ checkoutData.title }}</h5>
                  </div>
                  <div class="price">
                    <h4>₹ {{ checkoutData.final_price }}</h4>
                    <!-- <span>per year</span> -->
                  </div>
                </div>
                <div class="info">
                  <p v-if="checkoutData.board">
                    Board : {{ checkoutData.board }}
                  </p>
                  <p v-if="checkoutData.standard">
                    Standard : {{ checkoutData.standard }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 offset-md-1 cart-right">
          <div class="_right-wrap">
            <div class="_header">
              <h5>Payment Details</h5>
              <p>complete purchase by filling billing details</p>
            </div>
            <div class="_body">
              <form>
                <div class="form-group row">
                  <div class="col-md-6">
                    <label class="form-label">First Name <span>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="first_name"
                      placeholder="First Name"
                      v-model="studentData.first_name"
                    />
                    <div
                      class="input-errors"
                      v-for="error of v$.studentData.first_name.$errors"
                      :key="error.$uid"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">Last Name <span>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="last_name"
                      placeholder="Last Name"
                      v-model="studentData.last_name"
                    />
                    <div
                      class="input-errors"
                      v-for="error of v$.studentData.last_name.$errors"
                      :key="error.$uid"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label"
                      >Country Code <span>*</span></label
                    >
                    <Multiselect
                      v-model="countryCodeOptions.value"
                      v-bind="countryCodeOptions"
                    />
                    <div
                      class="input-errors"
                      v-for="error of v$.countryCodeOptions.value.$errors"
                      :key="error.$uid"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label"
                      >Phone Number <span>*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      placeholder="Without country code"
                      v-model.number="studentData.phone"
                    />
                    <div
                      class="input-errors"
                      v-for="error of v$.studentData.phone.$errors"
                      :key="error.$uid"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label class="form-label">Email <span>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      placeholder="Email Id"
                      v-model="studentData.email"
                    />
                    <div
                      class="input-errors"
                      v-for="error of v$.studentData.email.$errors"
                      :key="error.$uid"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label class="form-label"
                      >Billing Address <span>*</span></label
                    >
                    <textarea
                      v-model.trim="studentData.student_details.address"
                      class="form-control"
                      id="address"
                      rows="4"
                      placeholder="Billing Address"
                    ></textarea>
                    <div
                      class="input-errors"
                      v-for="error of v$.studentData.student_details.address
                        .$errors"
                      :key="error.$uid"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">ZIP code <span>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="zip_code"
                      placeholder="ZIP Code"
                      v-model="studentData.student_details.zip_code"
                    />
                    <div
                      class="input-errors"
                      v-for="error of v$.studentData.student_details.zip_code
                        .$errors"
                      :key="error.$uid"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">State <span>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="state"
                      placeholder="State"
                      v-model="studentData.student_details.state"
                    />
                    <div
                      class="input-errors"
                      v-for="error of v$.studentData.student_details.state
                        .$errors"
                      :key="error.$uid"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label>GST Number</label>
                    <input
                      type="text"
                      class="form-control"
                      id="gst_number"
                      placeholder="GST Number"
                      v-model="studentData.student_details.gst_number"
                    />
                  </div>

                  <div
                    v-if="checkoutData && checkoutData.final_price > 0"
                    class="col-md-12"
                  >
                    <label>Promo code</label>
                    <input
                      type="text"
                      class="form-control"
                      id="promo_code"
                      placeholder="Promo Code"
                      v-model="promo_code"
                      @keyup="checkEnteredPromoCode"
                      :disabled="isPromoCodeApplied"
                    />
                  </div>
                  <div class="col-md-12">
                    <button
                      v-if="!loading && checkoutData.final_price > 0"
                      class="btn cta-clear"
                      @click.prevent="applyPromoCode"
                      :disabled="
                        isPromoCodeEntered === false ||
                        isPromoCodeApplied === true
                      "
                    >
                      <span v-if="isPromoCodeApplied">Applied</span>
                      <span v-else-if="isPromoCodeProcessing"
                        >Processing...</span
                      >
                      <span v-else>Apply Promo Code</span>
                    </button>
                  </div>
                  <div class="checkout-user-agreement">
                    By continuing, you agree to our
                    <router-link target="_blank" :to="{ name: 'PrivacyPolicy' }"
                      >Privacy Policy</router-link
                    >
                    and
                    <router-link
                      target="_blank"
                      :to="{ name: 'TermsAndConditions' }"
                      >Terms And Conditions</router-link
                    >.
                  </div>
                </div>
              </form>
              <hr />

              <ul v-if="checkoutData" class="list-unstyled">
                <li
                  v-if="
                    this.$route.name === 'CourseCheckout' &&
                    checkoutData.course_fee
                  "
                >
                  Course Fee <span>₹ {{ checkoutData.course_fee }}</span>
                </li>
                <li
                  v-if="
                    this.$route.name === 'CourseCheckout' &&
                    checkoutData.book_fee
                  "
                >
                  Book Fee <span>+ ₹ {{ checkoutData.book_fee }}</span>
                </li>
                <li v-if="this.$route.name === 'EventCheckout'">
                  Subtotal <span>₹ {{ checkoutData.final_price }}</span>
                </li>
                <li>
                  Offer <span>- ₹ {{ checkoutData.offer_amount }}</span>
                </li>
                <li>
                  GST ({{ checkoutData.gst_percentage }}%)
                  <span>+ ₹ {{ checkoutData.gst_amount }}</span>
                </li>
                <li>
                  Total <span>₹ {{ checkoutData.net_amount }}</span>
                </li>
              </ul>
              <button
                v-if="!loading"
                class="btn cta-clear"
                @click="completeCheckout"
                ref="checkoutContainer"
                :disabled="
                  isPaymentProcessing === true || isPromoCodeProcessing === true
                "
              >
                <span v-if="isPaymentProcessing">Processing...</span>
                <span v-else>Pay now</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService";
import CourseService from "@/services/CourseService";
import CommonService from "@/services/CommonService";
import Multiselect from "@vueform/multiselect";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  helpers,
  numeric,
  maxLength,
  minLength,
} from "@vuelidate/validators";

export default {
  name: "Checkout",
  components: {
    Multiselect,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      loading: true,
      isPromoCodeEntered: false,
      isPromoCodeProcessing: false,
      isPromoCodeApplied: false,
      isPaymentProcessing: false,
      checkoutData: null,
      fullPage: true,
      // declaring the studentData based on the obtained response array
      studentData: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        student_details: {
          address: "",
          zip_code: "",
          state: "",
          gst_number: "",
        },
      },
      promo_code: "",
      // Select options
      countryCodeOptions: {
        value: null,
        options: [],
        placeholder: "Select Country Code",
        canDeselect: false,
        loading: true,
        // required: true,
        searchable: true,
        // disabled: false,
      },
    };
  },
  validations() {
    return {
      studentData: {
        first_name: {
          required: helpers.withMessage("Please enter first name", required),
        },
        last_name: {
          required: helpers.withMessage("Please enter last name", required),
        },
        email: {
          required: helpers.withMessage("Please enter email", required),
          email: helpers.withMessage("Please enter valid email", email),
        },
        phone: {
          required: helpers.withMessage("Please enter phone number", required),
          numeric: helpers.withMessage(
            "Please enter a valid contact no.",
            numeric
          ),
          maxLength: helpers.withMessage(
            "Max of 15 digits allowed",
            maxLength(15)
          ),
          minLength: helpers.withMessage(
            "It should be atleast of 6 digits",
            minLength(6)
          ),
        },
        student_details: {
          address: {
            required: helpers.withMessage("Please enter address", required),
          },
          zip_code: {
            required: helpers.withMessage("Please enter zipcode", required),
          },
          state: {
            required: helpers.withMessage("Please enter state", required),
          },
        },
      },
      countryCodeOptions: {
        value: {
          required: helpers.withMessage("Please select country code", required),
        },
      },
    };
  },
  mounted() {
    this.fetchDetails();
    this.allCountries();
  },
  computed: {
    switchRoute() {
      let routeData = {};
      let routeName = this.$route.name;
      // console.log(this.$route);
      if (routeName === "CourseCheckout") {
        routeData.service = CourseService;
        routeData.successRoute = "CoursePaymentSuccess";
        routeData.params = {
          program_id: this.$route.params.program_id,
          slug: this.$route.params.slug,
        };
      } else if (routeName === "EventCheckout") {
        routeData.service = EventService;
        routeData.successRoute = "EventPaymentSuccess";
        routeData.params = {
          event_id: this.$route.params.event_id,
          slug: this.$route.params.slug,
        };
      }

      return routeData;
    },
  },
  methods: {
    async fetchDetails() {
      const routeData = this.switchRoute;
      let purchaseService = routeData.service;
      let params = routeData.params;

      if (purchaseService !== undefined) {
        await purchaseService
          .initiateCheckout(params)
          .then((response) => {
            // console.log(response.data);
            if (response.data.status === "SUCCESS") {
              this.loading = false;
              this.checkoutData = response.data.checkout_data;
              this.studentData = response.data.student;
            }
            if (response.data.status === "ERROR") {
              this.$toast.error(response.data.message);
            }
            if (response.data.status === "VALIDATION_FAILED") {
              // console.log(response.data.errors);
              let errorsObject = response.data.errors;
              // Note:- Object.values gives the values as an array
              let errorValuesArray = Object.values(errorsObject);

              if (errorValuesArray.length > 0) {
                for (let index = 0; index < errorValuesArray.length; index++) {
                  this.$toast.error(errorValuesArray[index]);
                  // console.log(errorValuesArray[index]);
                }
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.$toast.warning("Something went wrong! Try again.");
      }
    },
    async allCountries() {
      await CommonService.getAllCountries()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.countryCodeOptions.options = []; // to clear previously loaded options
            this.countryCodeOptions.loading = false;
            let countries = response.data.countries;
            if (countries.length > 0) {
              countries.map((country) => {
                let options = {
                  value: country.phone_code,
                  label: country.country_name + " (" + country.phone_code + ")",
                };
                this.countryCodeOptions.options.push(options);
              });
              this.countryCodeOptions.value =
                this.studentData.phone_number_country_code;
            }
            // console.log(this.countryCodeOptions);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    checkEnteredPromoCode() {
      this.isPromoCodeApplied = false;
      if (this.promo_code.length > 0) {
        this.isPromoCodeEntered = true;
      } else {
        this.isPromoCodeEntered = false;
      }
    },
    applyPromoCode() {
      this.isPromoCodeEntered = true;
      this.isPromoCodeProcessing = true;

      // Initiate the promo code applying process
      const routeData = this.switchRoute;
      let purchaseService = routeData.service;
      let params = routeData.params;
      params.promo_code = this.promo_code;

      purchaseService
        .applyPromoCode(params)
        .then((response) => {
          // console.log(response.data);
          this.isPromoCodeProcessing = false;
          if (response.data.status === "SUCCESS") {
            this.isPromoCodeApplied = true;
            if (response.data.course_fee != null) {
              this.checkoutData.course_fee = response.data.course_fee;
            }
            this.checkoutData.offer_amount = response.data.offer_price;
            this.checkoutData.gst_amount = response.data.gst_amount;
            this.checkoutData.net_amount = response.data.net_amount;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    completeCheckout() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        // console.log(this.v$.$errors);
        return;
      }
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.checkoutContainer,
      });
      this.isPaymentProcessing = true;

      // Initiate the payment process
      const routeData = this.switchRoute;
      let purchaseService = routeData.service;
      let successRoute = routeData.successRoute;
      let params = routeData.params;
      (params.billing_first_name = this.studentData.first_name),
        (params.billing_last_name = this.studentData.last_name),
        (params.billing_email = this.studentData.email),
        (params.billing_phone_number_country_code =
          this.countryCodeOptions.value),
        (params.billing_phone = this.studentData.phone),
        (params.billing_address = this.studentData.student_details.address),
        (params.billing_zipcode = this.studentData.student_details.zip_code),
        (params.billing_state = this.studentData.student_details.state),
        (params.billing_gst_number =
          this.studentData.student_details.gst_number),
        (params.promo_code = this.promo_code);

      purchaseService
        .initiatePayment(params)
        .then((response) => {
          loader.hide();
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            if (response.data.total_amount_to_pay === 0) {
              this.$router.push({
                name: successRoute,
              });
            } else if (response.data.total_amount_to_pay > 0) {
              // initiating payment gateway
              this.processPayment(response.data);
            }
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            this.isPaymentProcessing = false;
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    processPayment(data) {
      this.isPaymentProcessing = false;
      let paymentData = data;
      var rzp1 = new window.Razorpay({
        key: process.env.VUE_APP_RAZOR_PAY_KEY_ID,
        amount: paymentData.total_amount_to_pay,
        name: "VBLive",
        currency: "INR",
        description: "Payment for Subscription",
        image: this.checkoutData.logo,
        handler: (response) => {
          this.isPaymentProcessing = true;
          // console.log(response);
          response.payment_id = paymentData.payment_id;
          this.completeSubscription(response);
        },
        modal: {
          ondismiss() {
            // window.location.replace("//put your redirect URL");
            // console.log("Modal closed by user");
            // FIXME: Error with undefined this operator
            // this.isPaymentProcessing = false;
          },
        },
        prefill: {
          name: this.studentData.student_name,
          email: this.studentData.email,
          contact:
            this.studentData.phone_number_country_code + this.studentData.phone,
        },
        notes: {
          payment_against: this.checkoutData.title,
          payment_code: paymentData.payment_code,
          address: this.studentData.student_details.address,
        },
        theme: {
          color: "#00ffff",
        },
      });
      rzp1.open();
      rzp1.on("payment.failed", function (response) {
        // alert(response.error.description);
        console.log(response.error.reason);
      });
    },
    completeSubscription(data) {
      // console.log(data);
      // Initiate the payment process
      const routeData = this.switchRoute;
      let purchaseService = routeData.service;
      let successRoute = routeData.successRoute;
      let params = {
        payment_id: data.payment_id,
        razorpay_payment_id: data.razorpay_payment_id,
      };
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.checkoutContainer,
      });

      purchaseService
        .activateSubscription(params)
        .then((response) => {
          loader.hide();
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.$router.push({
              name: successRoute,
            });
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/cart.scss";
</style>
